<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1>{{ $t('talk_about_us.title') }}</h1>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "TalkAboutUs"
    }
</script>

<style scoped>

</style>
